import axios from 'axios';
import router from '@/router'
import { message } from 'element-ui'

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

const require = axios.create({
  baseURL: 'https://api.kkpaywallet.com/api',
  // 超时时间
  timeout: 30000
})

require.interceptors.request.use(config => {
  config.headers['Authorization'] = `Bearer ${ sessionStorage.getItem('token') }`
  return config
},
  error => {
    return Promise.reject(error)
  }
)
let tokenmap = [401]
require.interceptors.response.use(
  response => {
    if (tokenmap.some(item => item == response.data.status)) {
      sessionStorage.removeItem("token")
    }
    if (response.status != 200) {
      message({
        message: response.data.message,
        type: 'error',
      })
    } else {
      const res = response.data
      return Promise.resolve(res)
    }
  },
  (error) => {
    if (error.response.status == 401) {
      sessionStorage.removeItem("token")
      message({
        message: '登录过期请重新登录',
        type: 'error',
      })
      window.location.href = "/#/login"
    }
    message({
      message: error.response.data.message || error.message,
      type: 'error',
    })
    return Promise.reject(error)
  }
)

export default require