import request from '@/request/index.js'
// 商户列表
export function bookkeeping (data) {
  return request({
    url: '/bookkeeping',
    method: 'post',
    data: data,
  })
}

//游戏
export function getBankerStatistics (data) {
  return request({
    url: '/getBankerStatistics',
    method: 'post',
    data: data,
  })
}



//校验赔率
export function checkDiceOddsApi (data) {
  return request({
    url: '/checkDiceOddsApi',
    method: 'post',
    data: data,
  })
}



//校验赔率
export function checkPc28OddsApi (data) {
  return request({
    url: '/checkPc28OddsApi',
    method: 'post',
    data: data,
  })
}

//校验赔率
export function checkWp28OddsApi (data) {
  return request({
    url: '/checkWp28OddsApi',
    method: 'post',
    data: data,
  })
}


//校验赔率
export function checkPc28gaoddsApi (data) {
  return request({
    url: '/checkPc28gaoddsApi',
    method: 'post',
    data: data,
  })
}
