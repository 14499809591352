import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/accounting',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    // peilv
    path: '/diceOdds',
    name: 'about',
    component: () => import('../views/peilv.vue')
  },
  {
    // pc28
    path: '/Pc28odds',
    name: 'about',
    component: () => import('../views/pc28.vue')
  }
  ,
  {
    // wp28
    path: '/wp28',
    name: 'wp28',
    component: () => import('../views/wp28.vue')
  },
  {
    // wp28
    path: '/wp28odds',
    name: 'wp28odds',
    component: () => import('../views/wp28odds.vue')
  },
  {
    // wp28
    path: '/pc28gaoodds',
    name: 'pc28gaoodds',
    component: () => import('../views/pc28gaoodds.vue')
  },
  {
    path: '/16niuniuOdds',
    name: '16niuniuOdds',
    component: () => import('../views/16niuniuOdds.vue')

  }
]
const router = new VueRouter({
  // hash  history
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
