<template>
  <div class="home" v-show="tableData.all_income">
    <div class="main">
      <div class="Inquire">
        <div class="" style="margin: 5px;"> <el-select v-model="inquireTime" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.created_at" :value="item.txid">
            </el-option>
          </el-select>
          <el-button @click="getbookkeepingdata" :loading="loading">账单查询</el-button>
        </div>
        <div class="" style="margin: 5px;">
          <el-date-picker v-model="txid" type="datetime" placeholder="选择日期时间" align="right"
            :picker-options="pickerOptions">
          </el-date-picker>
          <el-button @click="getbookkeepingBydate" :loading="loading">日期查询</el-button>
        </div>
      </div>
      <div class="deposit" v-loading="loading">
        <h1>入款({{ tableData.income?.length || 0 }})</h1>
        <div class="table">
          <el-table :data="tableData.income" style="width: 100%" border>
            <el-table-column prop="date" label="时间" align='center'>
            </el-table-column>
            <el-table-column prop="amount" label="金额" align='center' min-width="160px">
              <template slot-scope="scope">
                <div class="">
                  <p style="margin: 0;">
                    {{ Number(scope.row.amount).toFixed(3) }}
                  </p>
                  <p style="margin: 0;display: flex; justify-content: center; align-items: center;">
                    <svg class="tg-icon " style="margin-right: 4px;" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 32 32">
                      <g fill="none" fill-rule="evenodd">
                        <circle cx="16" cy="16" r="16" fill="#26A17B"></circle>
                        <path fill="#FFF"
                          d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117">
                        </path>
                      </g>
                    </svg><span>{{ Number(scope.row.u_amount).toFixed(2) }}</span><span>({{
                      Number(scope.row.rate).toFixed(2)
                      }})</span>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="reply_user" label="回复人" align='center'>
            </el-table-column>
            <el-table-column prop="user" label="记账人" align='center'>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="deposit" v-loading="loading">
        <h1>出款({{ tableData.expend?.length || 0 }})</h1>
        <div class="table">
          <el-table :data="tableData.expend" style="width: 100%" border center>
            <el-table-column prop="date" label="时间" align='center'>
            </el-table-column>
            <el-table-column prop="amount" label="金额" align='center' min-width="160px">
              <template slot-scope="scope">
                <div class="">
                  <p style="margin: 0;">
                    {{ Number(scope.row.amount).toFixed(3) }}
                  </p>
                  <p style="margin: 0;display: flex; justify-content: center; align-items: center;">
                    <svg class="tg-icon " style="margin-right: 4px;" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 32 32">
                      <g fill="none" fill-rule="evenodd">
                        <circle cx="16" cy="16" r="16" fill="#26A17B"></circle>
                        <path fill="#FFF"
                          d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117">
                        </path>
                      </g>
                    </svg><span>{{ Number(scope.row.u_amount).toFixed(2) }}</span><span>({{
                      Number(scope.row.rate).toFixed(2)
                      }})</span>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="reply_user" label="回复人" align='center'>
            </el-table-column>
            <el-table-column prop="user" label="记账人" align='center'>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="other" v-loading="loading">
        <h1>记账分类</h1>
        <el-collapse v-model="activeNames">
          <el-collapse-item title="按用户分类" name="1">
            <h3>共{{ tableData.userData?.length || 0 }}组</h3>
            <el-table :data="tableData.userData" style="width: 100%" border>
              <el-table-column prop="income_amount" label="总入">
              </el-table-column>
              <el-table-column prop="expend_amount" label="总出">
              </el-table-column>
              <el-table-column prop="profit_amount" label="剩余">
              </el-table-column>
              <el-table-column prop="user" label="用户">
              </el-table-column>
            </el-table>
          </el-collapse-item>
          <el-collapse-item title="按入款汇率分类" name="2">
            <h3>共{{ tableData.rateData?.length || 0 }}组</h3>
            <el-table :data="tableData.rateData" style="width: 100%" border>
              <el-table-column prop="rate" label="汇率">
              </el-table-column>
              <el-table-column prop="income_amount" label="总入">
              </el-table-column>
              <el-table-column prop="income_u_amount" label="总入U">
              </el-table-column>
            </el-table>
          </el-collapse-item>
          <el-collapse-item title="按入款汇率和用户分类" name="3">
            <h3>共{{ tableData.userRateData?.length || 0 }}组</h3>
            <el-table :data="tableData.userRateData" style="width: 100%" border>
              <el-table-column prop="user" label="用户">
              </el-table-column>
              <el-table-column prop="rate" label="汇率">
              </el-table-column>
              <el-table-column prop="income_amount" label="总入">
              </el-table-column>
              <el-table-column prop="income_u_amount" label="总入U">
              </el-table-column>

            </el-table>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="total" v-loading="loading">
        <h1>总计</h1>
        <div class="centent">
          <div class="list">
            <div class="label">总入款:</div>
            <div class="value">{{ tableData.all_income }}</div>
          </div>
          <div class="list">
            <div class="label">应下发:</div>
            <div class="value">{{ tableData.yingxiafa }} | {{ tableData.yingxiafaU }}<svg data-v-07df6972=""
                class="tg-icon ml-5px" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <circle cx="16" cy="16" r="16" fill="#26A17B"></circle>
                  <path fill="#FFF"
                    d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117">
                  </path>
                </g>
              </svg></div>
          </div>
          <div class="list">
            <div class="label">已下发:</div>
            <div class="value">{{ tableData.yixiafa }} | {{ tableData.yixiafaU }}
              <svg data-v-07df6972="" class="tg-icon ml-5px" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                width="16" height="16" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <circle cx="16" cy="16" r="16" fill="#26A17B"></circle>
                  <path fill="#FFF"
                    d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117">
                  </path>
                </g>
              </svg>
            </div>
          </div>
          <div class="list">
            <div class="label">待下发:</div>
            <div class="value">{{ tableData.yingxiafa - tableData.yixiafa }} | {{ tableData.yingxiafaU -
              tableData.yixiafaU }}
              <svg data-v-07df6972="" class="tg-icon ml-5px" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                width="16" height="16" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <circle cx="16" cy="16" r="16" fill="#26A17B"></circle>
                  <path fill="#FFF"
                    d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117">
                  </path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bookkeeping } from '@/api/index'
export default {
  name: 'HomeView',
  data() {
    return {
      options: [],
      inquireTime: '',
      activeNames: '',
      tableData: { income: [], expend: [], userData: [], rateData: [], userRateData: [], all_income: '', yingxiafa: '', yingxiafaU: '', yixiafa: '', yixiafaU: '', },
      loading: false,
      txid: '',
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },

    }
  },
  mounted() {
    this.getbookkeeping()
  },
  methods: {
    async getbookkeeping() {
      const { data } = await bookkeeping({
        "group_id": this.$route.query.group_id || '-1001811846117',
        "txid": this.inquireTime || this.$route.query.txid || '1',
      })
      this.tableData = data
      this.options = data.lastOrders.map((item => {
        return {
          created_at: this.$dayjs(item.created_at).format('YYYY-MM-DD'),
          txid: item.txid
        }
      }))
    },
    async getbookkeepingdata() {
      this.loading = true
      const { data } = await bookkeeping({
        "group_id": this.$route.query.group_id || '-1001811846117',
        "txid": this.inquireTime || this.$route.query.txid || '1',
      })
      this.tableData = data
      this.loading = false
    },
    async getbookkeepingBydate() {
      this.loading = true
      const { data } = await bookkeeping({
        "group_id": this.$route.query.group_id || '-1001811846117',
        "txid": this.$dayjs(this.txid).format('YYYY-MM-DD') || '1',
      })
      this.tableData = data
      this.loading = false
    },
  }

}
</script>
<style lang="scss" scoped>
.home {
  .main {
    max-width: 1200px;
    margin: 0 auto;

    .Inquire {
      padding-top: 30px;
      margin-bottom: 40px;
      display: flex;
      flex-wrap: wrap;

      .el-button {
        margin-left: 20px;
        background-color: #40a7e3;
        color: #fff;
      }
    }

    .deposit {
      margin-bottom: 10px;

      h1 {
        font-weight: 400;
        font-size: 18px;
      }
    }

    .other {
      margin-top: 30px;

      h1 {
        font-weight: 400;
        font-size: 18px;
      }

      ::v-deep .el-collapse-item__content {
        h3 {
          padding: 0 10px;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }

    .total {
      margin-top: 40px;

      h1 {
        font-weight: 400;
        font-size: 18px;
      }

      .centent {
        .list {
          font-size: 16px;
          display: flex;
          margin-bottom: 10px;

          .label {
            margin-right: 20px;
          }
        }
      }
    }
  }

  ::v-deep .el-table .cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
